import "../styles/etusivu.css"

function Etusivu() {
  return (
    <div className="home-container" id="etusivu">
      <div className="slogan">
        <h1>IT-ratkaisut</h1>
      </div>
      
    </div>
  );
}

export default Etusivu;